<template>
  <div class="container">
    <!-- <div class="title">
      <img src="@/assets/images/logo.jpg" alt="" width="100px" height="100px" />
    </div> -->
    <div class="titleBox">
      <div class="logo"></div>
    </div>

    <van-form>
      <van-cell-group>
        <van-field v-model="loginForm.phone" left-icon="contact" name="手机号1" label=" " label-width="10"
          input-align="left" placeholder="手机号" :rules="[
          { required: true, message: '请填写手机号' },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: '请输入11位有效手机号',
            trigger: 'blur',
          },
        ]" />

        <van-field v-model="loginForm.code" left-icon="closed-eye" center clearable label=" " label-width="10"
          placeholder="短信验证码">
          <template #button>
            <van-button size="small" type="default" :disabled="disabled" @click="sendSMSCode">{{ showText }}</van-button>
            <!-- <van-button v-else-if="cutDownTime" size="small" type="primary">{{currentTime}}s</van-button> -->
          </template>
        </van-field>
      </van-cell-group>
      <div class="bindPhone">
        <van-button round block type="info" @click="submit"> 登录 </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { Toast } from "vant";
import { getPhoneCodeAPI, loginAPI } from "@/api/index";
import { setStore, getStore } from "@/utils/localStorage.js";
import { loadCSS } from "@/api/base/menuBackground";
import website from '@/config/website';
import dateUtil from "@/utils/dateUtil.js";
export default {
  data() {
    return {
      cutDownTime: false,
      currentTime: 60,
      show: true,
      abled: false,
      disabled: false,
      showText: "发送验证码",
      refresh_token: "",
      loginForm: {
        phone: "",
        code: "",
        tenantId: "",
        token: "",
        type: "account", // 账号类型
      },
    };
  },

  created() {
    this.loginForm.phone = localStorage.getItem("mobile") == "undefined" ? "" : localStorage.getItem("mobile");

    this.loginForm.tenantId = this.$route.query.tenantId ? this.$route.query.tenantId : website.tenantId;
    console.log(this.loginForm.tenantId);
    loadCSS(localStorage.getItem("qwxCss"), function () {
      console.log("加载成功");
    });
  },

  methods: {
    sendSMSCode() {
      const regex = /^1[3456789]\d{9}$/;
      if (this.loginForm.phone == "") {
        Notify({ type: "warning", message: "手机号不能为空" });
        return;
      } else if (!regex.test(this.loginForm.phone)) {
        Notify({ type: "warning", message: "手机号格式不对" });
        return;
      } else {
        if (this.disabled == true) {
          return;
        }
        this.disabled = true;
        getPhoneCodeAPI(this.loginForm.phone, this.loginForm.tenantId)
          .then((res) => {
            let { currentTime } = this;
            const interval = setInterval(() => {
              this.showText = `${(currentTime -= 1)}s重新发送`;

              if (currentTime <= 0) {
                // 倒计时等于0时解除定时器
                this.disabled = false;
                this.showText = "发送验证码";
                clearInterval(interval); // 接除定时器
              }
            }, 1000);
          })
          .catch((error) => {
            if (error.message == "3分钟内不可重复获取验证码!") {
              this.showText = "发送验证码";
            }
            Toast(error.message);
          });
      }
    },

    // 登录
    submit() {
      loginAPI(
        this.loginForm.tenantId,
        this.loginForm.phone,
        this.loginForm.code,
        this.loginForm.type
      )
        .then((res) => {
          const obj = {}
          obj.date = new Date().getTime()
          obj.dateTime = dateUtil.formatDate2(new Date())
          const objString = JSON.stringify(obj); // 由于 localStorage 只能保存字符串内容，所以这里要先把对象转换成 JSON 字符串
          localStorage.setItem('data', objString)

          this.token = res.data.access_token;
          this.refresh_token = res.data.refresh_token;
          console.log(this.refresh_token, "refresh_token");
          localStorage.setItem("refresh_token", this.refresh_token);
          localStorage.setItem("mobile", this.loginForm.phone);
          localStorage.setItem("token", this.token);
          console.log("this.token:", this.token);
          localStorage.setItem("tenantId", this.loginForm.tenantId);
          this.$router.replace({ path: "/menu" });
        })
        .catch((error) => {
          Toast(error.message);
        });
    },
  },
};
</script>

<style scoped>
.titleBox {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  width: 100px;
  height: 100px;
  background: url("../../assets/images/logo.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.container {
  position: relative;
  box-sizing: border-box;
}

.bindPhone {
  margin: 100px 20px;
}

.van-cell {
  line-height: 40px;
}

.van-field {
  font-size: 16px;
}
</style>
