var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_vm._m(0),_c('van-form',[_c('van-cell-group',[_c('van-field',{attrs:{"left-icon":"contact","name":"手机号1","label":" ","label-width":"10","input-align":"left","placeholder":"手机号","rules":[
        { required: true, message: '请填写手机号' },
        {
          pattern: /^1[3456789]\d{9}$/,
          message: '请输入11位有效手机号',
          trigger: 'blur',
        },
      ]},model:{value:(_vm.loginForm.phone),callback:function ($$v) {_vm.$set(_vm.loginForm, "phone", $$v)},expression:"loginForm.phone"}}),_c('van-field',{attrs:{"left-icon":"closed-eye","center":"","clearable":"","label":" ","label-width":"10","placeholder":"短信验证码"},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('van-button',{attrs:{"size":"small","type":"default","disabled":_vm.disabled},on:{"click":_vm.sendSMSCode}},[_vm._v(_vm._s(_vm.showText))])]},proxy:true}]),model:{value:(_vm.loginForm.code),callback:function ($$v) {_vm.$set(_vm.loginForm, "code", $$v)},expression:"loginForm.code"}})],1),_c('div',{staticClass:"bindPhone"},[_c('van-button',{attrs:{"round":"","block":"","type":"info"},on:{"click":_vm.submit}},[_vm._v(" 登录 ")])],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"titleBox"},[_c('div',{staticClass:"logo"})])
}]

export { render, staticRenderFns }